<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div
                    class="col-md-1"
                    v-if="$store.getters.can('com.postulaciones.index')"
                  >
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm text-nowrap"
                >
                  <thead>
                    <tr>
                      <th class="text-center">Subasta</th>
                      <th class="col-2 text-center">Fecha Subasta</th>
                      <th class="text-center">Cliente</th>
                      <th class="text-center">Campo</th>
                      <th class="text-center">Producto</th>
                      <th class="text-center">Estado</th>
                      <th class="text-center">Postulados</th>
                      <th class="col-1 text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="ganador in ganadores.data"
                      :key="ganador.id"
                      style="font-size: 12px;"
                    >
                      <td>{{ ganador.subasta.nombre }}</td>
                      <td class="text-center">
                        {{ ganador.subasta.fecha_ini }} -
                        {{ ganador.subasta.fecha_fin }}
                      </td>
                      <td v-if="ganador.cliente">
                        {{ ganador.cliente.razon_social }}
                      </td>
                      <td v-else></td>
                      <td>{{ ganador.subasta_producto.sitio.nombre }}</td>
                      <td>{{ ganador.subasta_producto.producto.nombre }}</td>

                      <td class="col-1 text-center">
                        <span
                          class="badge"
                          :class="
                            ganador.estado == 1
                              ? 'bg-warinig'
                              : ganador.estado == 2
                              ? 'bg-success'
                              : ganador.estado == 3
                              ? 'bg-lightblue'
                              : 'bg-danger'
                          "
                        >
                          {{ ganador.nEstado }}
                        </span>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm"
                          data-toggle="modal"
                          :data-target="'#myModal_' + ganador.id"
                        >
                        <i class="fas fa-archive"></i>
                        </button>
                        <div
                          v-for="ganador in ganadores.data"
                          :key="ganador.id"
                        >
                          <div
                            class="modal fade"
                            :id="'myModal_' + ganador.id"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="myModalLabel">
                                    Detalles
                                  </h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <table class="table table-bordered table-striped table-hover table-sm">
                                    <thead class="bg-dark">
                                      <tr>
                                        <th>Fecha</th>
                                        <th>Aceptados</th>
                                        <th>Ofertados</th>
                                        <th>Postulados</th>
                                        <th>Usados</th>
                                      </tr>

                                    </thead>
                                    <tr v-for="det in ganador.detalles" :key="det.id">
                                      <td>{{ det.fecha }}</td>
                                      <td>{{ det.cant_vehi }}</td>
                                      <td>{{ det.cant_aceptada }}</td>
                                      <td>{{ det.postulaciones.length }}</td>
                                      <td>{{ det.postulaciones.filter(
                                        (item) =>item.estado  == 2 
                                      ).length }}</td>
                                    </tr>
                                  </table>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Cerrar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="col-1 text-right">
                        <div class="btn-group">
                          <button
                            type="button"
                            v-if="$store.getters.can('com.postulaciones.edit')"
                            @click="asignarPostulaciones(ganador.id, 1)"
                            class="btn btn-sm bg-navy"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            v-if="$store.getters.can('com.postulaciones.show')"
                            @click="asignarPostulaciones(ganador.id, 2)"
                            class="btn btn-sm bg-info"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="ganadores.total">
                  <p>
                    Mostrando del <b>{{ ganadores.from }}</b> al
                    <b>{{ ganadores.to }}</b> de un total:
                    <b>{{ ganadores.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="ganadores"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";

export default {
  name: "PostulacionIndex",
  components: {
    Loading,
    pagination,
  },

  data() {
    return {
      cargando: true,
      ganadores: {},
      filtros: {},
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      let me = this;

      axios
        .get("/api/com/postulaciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.ganadores = response.data;
          this.cargando = false;
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    // Métodos de acciónes del módulo
    asignarPostulaciones(oferta_id, accion) {
      return this.$router.push({
        name: "/Com/PostulacionesForm",
        params: { oferta_id: oferta_id, accion: accion },
      });
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
